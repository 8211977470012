/* styles/mediaViewer.css */

.media-viewer-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.media-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
}

.media-image, .media-video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.media-info {
    margin-top: 10px;
    text-align: center;
}

.media-info h3 {
    margin-bottom: 8px;
    font-size: 18px;
}

.media-info p {
    font-size: 14px;
    color: #666;
}
